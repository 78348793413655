var api = require('etherscan-api').init('TR7N74I7XAUTW7IBB38ET9TADN1RVGSDG4');

const timeConverter = (UNIX_timestamp) => {
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  return time;
}


export const fetchTransactions = async() => {
  let date = "Pending...";

  let transactions = await api.account.txlist("0x2b19C229C8e79C6502Ef19Afc1260a4387Ee599e");

  for (const transaction of transactions.result) {

    if (transaction.from == 0x616Fc7C9c690E0f5e2198DCD69C3a8b53481C69B) {
      date = timeConverter(transaction.timeStamp);
    }
  }
  return date;
}
