import React, { useState, useEffect } from "react";
import BoredPunk from "../../contracts/BoredPunk.js";
import {
  connectWallet,
  getCurrentWalletConnected
} from "../../utils/connect.js";
import { fetchTransactions } from "../../utils/helpers.js";
import connect from "../../assets/CONNECT.png";
import buy from "../../assets/BUY.png";
import claim from "../../assets/CLAIM.png";

import "./Vault.css";

function TokenSwap(props) {
  const [date, setDate] = useState("");
  const [wallet, setWallet] = useState("");
  const [balance, setBalance] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");

  const connectWalletPressed = async () => setWallet(await connectWallet());

  const addWalletListener = async () => {
    try {
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", accounts =>
          setWallet(accounts.length > 0 ? accounts[0] : "")
        );

        let address = await getCurrentWalletConnected();

        setWallet(address);
        addWalletListener();
        setBalance(await BoredPunk.methods.balanceOf(address).call());
      }
    } catch (e) {
      console.log("Error in Wallet Listener.");
    }
  };

  const claimRevenue = async () => {
    setTransactionStatus(`Fetching Bored Punks in ${wallet}. Please wait.`);

    const tokens = [];

    try {
      setBalance(await BoredPunk.methods.balanceOf(wallet).call());
      if (balance == 0) return;
      const totalSupply = await BoredPunk.methods.totalSupply().call();

      for (let i = 0; i < 889; i++) {
        try {
          let owner = await BoredPunk.methods.ownerOf(i).call();

          if (owner.toLowerCase() == wallet) {
            tokens.push(i);
            setTransactionStatus(
              `Bored Punk ${i} located. The search continues...`
            );
          }

          if (tokens.length == balance) {
            setTransactionStatus(
              `Bored Punk${
                tokens.length > 1 ? "s" : ""
              } ${tokens.toString()} located.`
            );
            break;
          }
        } catch (e) {
          console.log(`Bored Punk ${i} has not been minted.`);
        }
      }
    } catch (e) {
      setTransactionStatus(`Please Try Again.`);
    }

    try {
      setTransactionStatus(`Transaction appearing...`);

      let gas = await BoredPunk.methods
        .claimMultipleRewards(tokens)
        .estimateGas({
          from: wallet
        });
      gas = Number.parseInt(gas * 1.1);

      let result = await BoredPunk.methods.claimMultipleRewards(tokens).send({
        from: wallet,
        gasLimit: gas
      });

      setTransactionStatus(
        `Success: https://etherscan.io/tx/${result.transactionHash}`
      );
    } catch (e) {
      setTransactionStatus(`Please Try Again.`);
    }
  };

  useEffect(async () => {
    try {
      let address = await getCurrentWalletConnected();

      setWallet(address);
      addWalletListener();
      setBalance(await BoredPunk.methods.balanceOf(address).call());

      setDate(await fetchTransactions());
    } catch (e) {
      setTransactionStatus(`Please Try Again.`);
    }
  }, []);

  return (
    <div className={props.show ? "vault-container" : "hide"}>
      <div className="wallet">
        {wallet.length == 0 ? (
          <img
            className="connect-button"
            src={connect}
            onClick={connectWalletPressed}
          />
        ) : (
          <p>{String(wallet)}</p>
        )}
      </div>

      {balance > 0 ? (
        <React.Fragment>
          <h1>Welcome to The Boring Vault</h1>
          <p>
            Where 5% of all Bored Punk sales (excluding private sales) are
            safely stored, automatically divided and equally allocated to each
            and every Bored Punk minted in the new contract until they’re
            claimed by the wallets holding those Bored Punks.
          </p>

          <h1>How does it work:</h1>
          <p>
            Whenever the royalties from a marketplace (Opensea, Rarible,
            Cargo,…) are received by the BPYC smart contract, they’ll be
            automatically divided into equal parts corresponding to the number
            of minted tokens in the contract at that time (ideally 888 when the
            entire collection is migrated)
            <br/>
            <br/>
            Then the equally divided royalties will be tied to the Token ID of
            each Bored Punk, when you open the Boring Vault and connect your
            wallet, the contract will verify the Bored Punks you have in that
            wallet and lets you claim all the available royalties for those
            Bored Punks with the “claim” button in one transaction that you only
            have to pay the gas fee for.
          </p>

          <h1>Important Notes:</h1>
          <p>
            All royalties are automatically divided and allocated to Bored Punk
            Tokens and will be claimable from the exact moment they are sent to
            the BPYC contract from Opensea or other marketplaces.
            <br/>
            <br/>
            However the BPYC smart contract cannot bypass the payout timeframes
            of Opensea and/or other marketplaces So please check the “Last
            Received Date” indicator below before hitting the claim button to
            make sure your unclaimed royalties have been delivered to The Boring
            Vault.
            <br/>
            <br/>
            You can get a fairly accurate estimation of the royalties earned by
            each Bored Punk in each delivery by dividing 5% of the monthly
            traded volume of the collection on Opensea by the number of
            currently minted Bored Punks in the new contract.
            <br/>
            <br/>
          </p>

          <img
            className="claim-button"
            src={claim}
            alt="swap"
            onClick={claimRevenue}
          />

          <p>Last Open Sea Payout Date: {date}</p>
        </React.Fragment>
      ) : (
        <div className="denied">
          <h1> Access Denied </h1>
          <p> Purchase a Bored Punk to gain access to the vault. </p>
          <a
            href="https://opensea.io/collection/boredpunkyachtclub"
            target="_blank"
            rel="noreferrer"
          >
            <img className="buy-button" src={buy} alt="swap" />
          </a>
        </div>
      )}

      <p className="center">{transactionStatus}</p>
    </div>
  );
}

export default TokenSwap;
